import React, { useState, useEffect }from 'react';
import { Link } from "react-router-dom";

import AddButton from './AddButton.js';
import CartQuantityPicker from './CartQuantityPicker.js';

import '../css/cart.css';
import '../css/addbutton.css';

const Cart = (props)=>{
  console.log(props)
  const [estimatedTotal, setEstimatedTotal] = useState(0);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const updateWindow = ()=>{
    setWindowInnerWidth(window.innerWidth);
  };

  window.addEventListener('resize', updateWindow);

  useEffect(()=>{
    let newTotal = 0;
    props.cart.forEach((item)=>{
      if(!isNaN(item.Price)){
        newTotal += item.Price * item.Quantity;
      }
      setEstimatedTotal(newTotal);
    });
  },[props.cart]);

  return(
    <div className='body'>
      <div className="title">
        YOUR COLLECTION
        {
          props.cart.length ?
              <Link to="/" className="subtitle">
                <div className="subtitle">CONTINUE SEARCHING</div>
              </Link>
          :
            ''
        }
      </div>
      <div className='main'>
        <div className='items'>
          {
            props.cart.length ?
              props.cart.map((item, index)=>(
                  <div key={index} className='item_info'>
                    <div className='item_image'><img src={item.ImageLink} style={{width:'100%'}} alt="product pic"/></div>
                    <div style={{paddingLeft: '20px', paddingTop: '0px'}}>
                      <div style={{fontWeight:'lighter'}}>{item.Name}</div>
                      <div style={{textAlign: 'left', fontSize:'calc(6px + 1vmin)', fontWeight:'normal', marginTop:'5px'}}>{item.Length}"H x {item.Width}"W {item.Depth > 0 ? ` x ${item.Depth}"D` : ""}</div>

                      <div style={{fontSize:'calc(5px + 1vmin)', fontWeight:'normal', marginBottom:'3px', marginTop:'25px'}}>
                        Quantity
                      </div>
                      <CartQuantityPicker
                        quantity={item.Quantity}
                        item={item.ASIN}
                        imglink={item.ImageLink}
                        setCartItem={props.setCartItem}
                      />
                    </div>
                    {windowInnerWidth > 651 ?
                      <div style={{textAlign:'right', fontSize:'calc(5px + 1vmin)', fontWeight:'normal', alignSelf:'flex-end', flex:'1'}}>CAD<span style={{fontSize:'calc(6px + 1vmin)', fontWeight:'bold'}}>${!isNaN(item.Price) ? Math.round(item.Price*item.Quantity*100)/100 : "-"}</span><span style={{fontSize:'calc(4px + 1vmin)'}}> as of {item.As_Of_Price_Date}</span></div>
                      :
                      <div style={{marginLeft: '20px', marginTop:'20px', fontSize:'calc(5px + 1vmin)', fontWeight:'normal', alignSelf:'flex-start', flex:'1'}}>CAD<span style={{fontSize:'calc(6px + 1vmin)', fontWeight:'bold'}}>${!isNaN(item.Price) ? Math.round(item.Price*item.Quantity*100)/100 : "-"}</span><span style={{fontSize:'calc(4px + 1vmin)'}}> as of {item.As_Of_Price_Date}</span></div>
                    }
                  </div>
              ))
            :
              <div className='items' style={{alignSelf:'center'}}>
                <div className='title'>
                  COLLECTION IS EMPTY
                </div>
                <Link to="/" style={{marginBottom: '20px'}}>
                  <AddButton
                    title="FIND YOUR STYLE"
                  />
                </Link>
              </div>
          }
        </div>
        {
          props.cart.length ?
            <div className='summary'>
              <div className="title">
                COLLECTION SUMMARY
              </div>
              <div className="subtitle" style={{fontWeight:'lighter'}}>
              Estimated Total CAD${Math.round(estimatedTotal*100)/100}
              </div>
              <div style={{marginTop:'20px'}}>
                  {
                    props.cart.length ?
                      <form method="GET" action="https://www.amazon.ca/gp/aws/cart/add.html" target="_blank">
                        <input type="hidden" name="AWSAccessKeyId" value="AKIAIF3N77DFOSS5KUZQ" /><br/> 
                        <input type="hidden" name="AssociateTag" value="mwstylefinds-20" />
                        {
                          props.cart.map((item,index) => (
                            <span key={index}>
                              <input key={`ASIN_${index+1}`} type="hidden" name={`ASIN.${index+1}`} value={item.ASIN} />
                              <input key={`Quantity_${index+1}`} type="hidden" name={`Quantity.${index+1}`} value={item.Quantity} />
                            </span>
                          ))
                        }
                        <input type="submit" className="button" name="add" value="CONTINUE TO AMAZON" onClick={props.saveCartDatabase}/>
                      </form>
                    :
                    <div></div>
                  }
              </div>
              <div className="summaryInfo">
                Continue to Amazon to purchase your MW Style Find items.
              </div>
              <div className="quoteInfo">
                Marsha Wheeler supports buying local, with many of her finds coming from Canadian suppliers.
              </div>
              <div className="quoteInfo" style={{marginBottom:"-20px"}}>
                Amazon Quotes
              </div>
              <div className="quoteInfo">
                "30,000 Canada-based third-party sellers from all 13 provinces and territories  – many of which are small and medium-sized businesses - grossed more than $1 billion on Amazon.ca in 2019, growing 40% year-over-year, and more than in $2 billion on Amazon’s stores around the world"
              </div>
              <div className="quoteInfo">
                "New `Support Small Storefront` on Amazon.ca spotlights SMB sellers from each region of Canada, including Women-Owned Businesses, Innovators & Handmakers, and Family-Focused Businesses"
              </div>
              <div className="quoteInfo">
                "Amazon Web Services (AWS) works with tens of thousands of Canadian startups, customers, and partners to launch and scale businesses that enrich their communities"
              </div>
              <div className="quoteInfo">
                <a href="https://press.aboutamazon.com/news-releases/news-release-details/amazon-canadas-first-smb-impact-report-highlights-success-small" className="link" target="_blank" rel="noopener noreferrer">Amazon Press Release</a>
              </div>
              <div className="quoteInfo" style={{marginTop:'1px'}}>
                <a href="https://www.dropbox.com/s/2yv2jsqoiglzn1f/2020_Amazon_Canada_SMB_Impact_Report.pdf?dl=0" className="link" target="_blank" rel="noopener noreferrer">2020 Amazon Canada SMB impact report</a>
              </div>
            </div>
          :
            ''
        }
      </div>
    </div>
  );
};

export default Cart;

/*
<input type="hidden" name="AWSAccessKeyId" value="Access Key ID" />
{
  props.cart.length ?
    <button style={{margin:20}} name="clear" value="clear"
      onClick={
        ()=>{
          props.clearCart()
        }
      }>Clear Cart
    </button>
  :
    ""
}
*/

/*
<div>
  <h2>Cart</h2>
  <ul>{
    props.cart.map((item, index) => <li key={index}>Item:{item.ASIN}<br/>Quantity:{item.Quantity}<br/><br/></li>)
  }</ul>
  {
    props.cart.length ?
      <form method="GET" action="https://www.amazon.ca/gp/aws/cart/add.html" target="_blank">
        <input type="hidden" name="AWSAccessKeyId" value="Access Key ID" />
        <input type="hidden" name="AssociateTag" value="Associate Tag" />
        {
          props.cart.map((item,index) => (
            <span key={index}>
              <input key={`ASIN_${index+1}`} type="hidden" name={`ASIN.${index+1}`} value={item.ASIN} />
              <input key={`Quantity_${index+1}`} type="hidden" name={`Quantity.${index+1}`} value={item.Quantity} />
            </span>
          ))
        }
        <input type="submit" name="add" value="Buy" onClick={props.saveCartDatabase}/>
      </form>
    :
    <div></div>
  }
  {
    props.cart.length ?
      <button
        style={{margin:20}}
        name="add"
        value="Buy"
        onClick={
          ()=>{
            props.saveCartDatabase()
          }
        }>Temp Buy Button
      </button>
    :
    <div></div>
  }
  {
    props.cart.length ?
      <button
        style={{margin:20}}
        name="clear"
        value="clear"
        onClick={
          ()=>{
            props.clearCart()
          }
        }>Clear Cart
      </button>
    :
    <div></div>
  }
  {console.log('current cart status in react app: ' + JSON.stringify(props.cart))}
  {console.log('current cart status in localStorage: ' + localStorage.getItem('cart'))}
</div>
*/
