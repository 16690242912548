import React from 'react';
import { Link } from "react-router-dom";

const Subdepartment = (props)=>{
  console.log(props)
  return(
    <div style={{display: 'flex', flexDirection: 'column', width:'95%'}}>
      <div style={{margin:'2vh', fontWeight:'normal'}}>{props.department.replace('_', ' ').toUpperCase()}</div>
      <div style={{marginBottom:'2vh', fontWeight:'lighter'}}>
        {
          Object.keys(props.departmentQuotes).includes(props.department) &&
            <div>{Object.values(props.departmentQuotes)[Object.keys(props.departmentQuotes).indexOf(props.department)]}</div>
        }
      </div>
      <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', width:'100%'}}>
      {
        Object.keys(props.subdepartments).map((department, index)=>(
          (department !== 'new arrivals') &&
            <Link to={`/${department}`} key={department} style={{paddingTop:'0px', textDecoration:'none', color:'black'}}>
              <div key={index} className="department">
                <div>
                  {
                    <img src={props.subdepartments[department][props.subdepartments[department].length-1].thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'200px'}} alt="department pic"/>
                  }
                </div>
                <div>
                  {department.replace('_', ' ')}
                </div>
              </div>
            </Link>
        ))
      }
      </div>
    </div>
  )
};

export default Subdepartment;
