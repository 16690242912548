import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import '../css/productpage.css';

const ProductPage = (props)=>{
  console.log(props);
  useEffect(()=>{
    props.setCurrentSelection(props.department);
    props.setMotherDepartment(props.motherDepartment);
    console.log('----------------------' + props.department)
  });
  return(
    <div style={{display: 'flex', flexDirection: 'column', width:'95%'}}>
      {
        props.department !== 'new arrivals' ?
          <div>
            <div style={{margin:'2vh', fontWeight:'normal'}}>{props.department.replace('_', ' ').toUpperCase()}</div>
            <div style={{marginBottom:'2vh', fontWeight:'lighter'}}>
              {
                Object.keys(props.departmentQuotes).includes(props.department) &&
                  <div>{Object.values(props.departmentQuotes)[Object.keys(props.departmentQuotes).indexOf(props.department)]}</div>
              }
            </div>
          </div>
        :
          <span style={{margin:'2vh', fontWeight:'normal'}}>NEW FINDS</span>
      }

      <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', width:'100%'}}>
        {
            props.currentProducts !== undefined &&
              props.currentProducts.map((product, index)=> (
                <Link to={`/showproduct`} key={product.product_id} onClick={()=>props.setShowProduct(product)} style={{paddingTop:'0px', textDecoration:'none', color:'black'}}>
                  <div key={index} className="productpage">
                    <div>
                      <img src={product.thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'200px'}} alt="product pic"/>
                    </div>
                    <div style={{display:'flex', flexFlow: 'column wrap', fontSize:'calc(8px + 1vmin)'}}>
                      <div>
                        {product.name}
                      </div>
                      <div style={{marginTop:'10px'}}>
                        CAD<span style={{fontSize:'calc(15px + 1vmin)', fontWeight:'bold'}}>${product.price}</span> <span style={{fontSize:'calc(4px + 1vmin)'}}>as of {product.as_of_price_date}</span>
                      </div>
                      {
                        product.was_price !== "" &&
                          <div style={{marginTop:'10px'}}>
                            <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal', color:"red"}}>SAVE </span><span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'bold'}}>{product.save}</span> <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal'}}>was: $<del>{product.was_price}</del></span>
                          </div>
                      }
                    </div>
                  </div>
                </Link>
              ))
        }
      </div>
    </div>
  )
};

export default ProductPage;
