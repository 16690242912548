import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCG0C5bhC1x6sKF62YB_TrjHbCoQcKEbfg",
  authDomain: "mwshop-da706.firebaseapp.com",
  databaseURL: "https://mwshop-da706.firebaseio.com",
  projectId: "mwshop-da706",
  storageBucket: "mwshop-da706.appspot.com",
  messagingSenderId: "483133606607",
  appId: "1:483133606607:web:951a4babdfc28f2d84c5ba",
  measurementId: "G-ES3ELLP5G2"
});

const auth = firebaseApp.auth();
const db = firebaseApp.firestore();

export {db, auth};
