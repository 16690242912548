import React, { useState, useRef } from 'react';
import { Link } from "react-router-dom";
//import lunr from "lunr";

import mwstylefinds from '../images/mw.svg';
//import logo from '../images/logo_no circle_small.jpg';
import CartLogo from './CartLogo.js';
import '../css/titlebar.css';

const Titlebar = (props)=>{
  console.log(props);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [showDropDown, setShowDropDown] = useState(false);
  const searchInput = useRef('');


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const keyPressSearchAlgorithm = (event)=>{
    console.log('keyPressSearch-------------------------------')
    props.setSearchValue(searchInput.current.value);

    if(!searchInput.current.value.includes('*') && !searchInput.current.value.includes('^') && !searchInput.current.value.includes(':') && !searchInput.current.value.includes('~') && !searchInput.current.value.includes('+') && !searchInput.current.value.includes('-')){
      props.setSearchResults(props.idx.search(searchInput.current.value));
    }

  }

  const searchAlgorithm=()=>{
    console.log('searchAlgorithm called .........................')
    props.setSearchValue(searchInput.current.value);

    if(!searchInput.current.value.includes('*') && !searchInput.current.value.includes('^') && !searchInput.current.value.includes(':') && !searchInput.current.value.includes('~') && !searchInput.current.value.includes('+') && !searchInput.current.value.includes('-')){
      props.setSearchResults(props.idx.search(searchInput.current.value));
    }

    searchInput.current.value = '';
  };
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

   //<div style={{width:'30%',height:'30px'}}><input type="text" placeholder="Find your style..."/><button><i className="fa fa-search"></i></button></div>
   const updateWindow = ()=>{
     setWindowInnerWidth(window.innerWidth);
   };

   window.addEventListener('resize', updateWindow);

   const toggleDropDown=()=>{
     if(showDropDown){
       setShowDropDown(false);
     }else{
       setShowDropDown(true);
     }
   };
  return(
      <div>
      {
        windowInnerWidth > 1200 ? ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          <div className="header-container">
            <div className="header">
              <div className="mw-logo"><Link to="/"><img src={mwstylefinds} alt="mwstylefinds"/></Link>
                <div className="dropdown">
                  <button className="dropbtn" onClick={()=>toggleDropDown()} onMouseOver={()=>setShowDropDown(true)}>Style Finds &#8801;</button>
                  {showDropDown &&
                    <div className="dropdown-content" onClick={()=>setShowDropDown(false)}>
                      <DropDownChoices info={props} />
                      <div><Link to="/">Home</Link></div>
                    </div>
                  }
                </div>
              </div>

              <div className="form-group fg--search">
                <Link to="/search" className="linkSearch">
                  <input type="text" className="input" placeholder="Find your style..." ref={searchInput} onKeyUp={keyPressSearchAlgorithm} />
                  <button onClick={()=>searchAlgorithm()}><i className="fa fa-search"></i></button>
                </Link>
              </div>

              <div className="title-menu">
                <a href="https://www.marshawheelerdesign.com/#servicesdesktop" className="title-link" style={{marginRight:'100px'}} target="_blank" rel="noopener noreferrer">Interior Styling</a>
                <Link to="/collection" className="title-link" style={{marginRight:'10px'}}>Your Collection</Link>
                <CartLogo cart={props.cart}/>
              </div>
            </div>
            <div className="title-menu" style={{borderBottom:'1px solid lightgrey', width:'100%', justifyContent:'center'}}>
              {
                props.departments.map((department,index)=>(
                  (department === 'new arrivals') &&
                    <Link to={`/new arrivals`} className="title-link" style={{padding:'5px 15px 5px 15px'}} key='new arrivals'>new finds</Link>
                ))
              }
              {
                props.departments.map((department, index)=>{
                    if(department === 'new arrivals'){
                      return ''
                    }else{
                      return <Link to={`/${department}`} className="title-link" style={department !== 'top finds' ? {padding:'5px 15px 5px 15px'} : {padding:'5px 15px 5px 15px', color:'red'}} key={department}>{department.replace('_', ' ')}</Link>
                    }

                })
              }

            </div>
          </div>
        :
          windowInnerWidth > 650 ? ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            <div className="header-container">
              <div className="header">
                <div className="mw-logo"><Link to="/"><img src={mwstylefinds} alt="mwstylefinds"/></Link>
                  <div className="dropdown">
                    <button className="dropbtn" onClick={()=>toggleDropDown()} onMouseOver={()=>setShowDropDown(true)}>Style Finds  &#8801;</button>
                    {showDropDown &&
                      <div className="dropdown-content" onClick={()=>setShowDropDown(false)}>
                        <DropDownChoices info={props} />
                        <a href="https://www.marshawheelerdesign.com/#servicesdesktop" style={{marginLeft:'20px',width:'70%',borderTop:'1px solid black'}} target="_blank" rel="noopener noreferrer">Interior Styling</a>
                        <Link to="/collection" className="title-link" style={{marginLeft:'20px',width:'70%'}}>Your Collection</Link>
                        <div><Link to="/">Home</Link></div>
                      </div>
                    }
                  </div>
                </div>

                <div className="form-group fg--search">
                  <Link to="/search" className="linkSearch">
                    <input type="text" className="input" placeholder="Find your style..." ref={searchInput} onKeyUp={keyPressSearchAlgorithm} />
                    <button onClick={()=>searchAlgorithm()}><i className="fa fa-search"></i></button>
                  </Link>
                </div>

                <div className="title-menu">
                  <CartLogo cart={props.cart}/>
                </div>
              </div>

            </div>
          :
            <div className="header-container">
              <div className="header">
                <div className="mw-logo"><Link to="/"><img src={mwstylefinds} alt="mwstylefinds"/></Link>
                  <div className="dropdown">
                    <button className="dropbtn" onClick={()=>toggleDropDown()}>Style Finds &#8801;</button>
                    {showDropDown &&
                      <div className="dropdown-content" onClick={()=>setShowDropDown(false)}>
                        <DropDownChoices info={props} />
                        <a href="https://www.marshawheelerdesign.com/#services" style={{marginLeft:'20px',width:'70%',borderTop:'1px solid black'}} target="_blank" rel="noopener noreferrer">Interior Styling</a>
                        <Link to="/collection" className="title-link" style={{marginLeft:'20px',width:'70%'}}>Your Collection</Link>
                        <div><Link to="/">Home</Link></div>
                      </div>
                    }
                  </div>
                </div>
                <div className="title-menu">
                  <CartLogo cart={props.cart}/>
                </div>
              </div>
              <div className="form-group fg--search">
                <Link to="/search" className="linkSearch">
                  <input type="text" className="input" placeholder="Find your style..." ref={searchInput} onKeyUp={keyPressSearchAlgorithm} />
                  <button onClick={()=>searchAlgorithm()}><i className="fa fa-search"></i></button>
                </Link>
              </div>
            </div>
      }
      </div>
  );
};

const DropDownChoices = (props)=> {
  console.log(props);
  return(
    <div>
    {
      props.info.departments.map((department,index)=>(
        (department === 'new arrivals') &&
          <Link to={`/new arrivals`} className="title-link" style={{padding:'5px 15px 5px 15px'}} key='new arrivals'>new finds</Link>
      ))
    }
    {
      props.info.departments.map((department, index)=>{
          if(department === 'new arrivals'){
            return ''
          }else{
            return <Link to={`/${department}`} className="title-link" style={department !== 'top finds' ? {padding:'5px 15px 5px 15px'} : {padding:'5px 15px 5px 15px', color:'red'}} key={department}>{department.replace('_', ' ')}</Link>
          }

      })
    }
    </div>
  );
};

export default Titlebar;
