import React from 'react';

import '../css/privacypolicy.css';

const PrivacyPolicy = ()=>{
  return (
    <div className="resource_main">
      <div className="resource_title">Privacy Policy</div>
      <div className="resource_paragraph">
        Marsha Wheeler Design and MW Style Finds protects personal information and will never sell, rent or share your information in any way and under any situation without your own authorization. We are firmly committed to our customers' privacy.
      </div>
      <div className="resource_sub_title">
      Cookies and IP Addresses
      </div>
      <div className="resource_paragraph">
        Your IP address is used to help identify you and your collection and gather general demographic information. We use cookies to keep track of your collection and for other purposes like personalizing the site. Cookies are a small piece of information stored by your internet browser to your computer's hard drive. Your browser is usually set up to initially accept cookies. If you set your browser to reject cookies, the site will not be able to recognize your collection, or any personalization. We do not and cannot use cookies to gather your confidential information that may be stored on your hard drive. Links to Third Party Company’s display their own privacy policy’s in regards to cookies and IP Addresses for your review.
      </div>
      <div className="resource_sub_title">
      Personal Information
      </div>
      <div className="resource_paragraph">
        Our site uses forms for you to request information and services. We collect your contact information, which includes email addresses and phone numbers when provided by you. All collected information is used for the benefit of Marsha Wheeler Design and MW Style Finds to send information to customers about services we provide, promotional marketing material about our company and great Style Finds we see on Amazon.ca.
      </div>
    </div>
  )
};

export default PrivacyPolicy;
