import React from 'react';

import '../css/cartquantitypicker.css';

const CartQuantityPicker = (props)=>{

  const decrementQuantity = ()=>{
    let qty = props.quantity;
    qty--;
    if(qty > 0){
      props.setCartItem(props.item, qty, props.imglink);
      //setQuantity(qty);
      //props.setCartUpdated(false);
    }
  };

  const incrementQuantity = ()=>{
    let qty = props.quantity;
    qty++;
    props.setCartItem(props.item, qty, props.imglink);
    //setQuantity(qty);
    //props.setCartUpdated(false);
  };

  const removeItem = ()=>{
    props.setCartItem(props.item, 0, props.imgLink);
  };

  return(
    <div style={{display:'flex'}}>
      <div className="decrement" onClick={()=>decrementQuantity()}>-</div>
      <div className="quantity">{props.quantity}</div>
      <div className="increment" onClick={()=>incrementQuantity()}>+</div>
      <div className="remove" onClick={()=>removeItem()}>Remove</div>
    </div>
  );
};

export default CartQuantityPicker;
