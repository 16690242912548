import React, { useState, useEffect } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { Link } from "react-router-dom";

import AddButton from './AddButton.js';
import QuantityPicker from './QuantityPicker.js';
import '../css/showproduct.css';

import maple_leaf from '../images/maple_leaf.svg';

const ShowProduct = (props)=>{
  console.log(props.allProducts);
  console.log(props);
  const [thumbChoice, setThumbChoice] = useState(0);
  const [quantity, setQuantity] = useState(1);

  useEffect(()=>{
    props.setCartUpdated(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setThumbChoice(0);
    setQuantity(1); //new
    props.setCartUpdated(false); /// new
    // eslint-disable-next-line
  }, [props.showProduct]);

  return(
    <div  className='maindiv'>
    {
      props.showProduct.length > 0 ?
        <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around', width:'100%', height:'100%'}}>
          <div className='showproductimage'>
            <ReactImageMagnify {...{
                smallImage: {
                    alt: 'Product Pic',
                    isFluidWidth: true,
                    src: `${props.showProduct.thumbs[thumbChoice]}`,
                },
                largeImage: {
                    alt: 'Product Pic',
                    src: `${props.showProduct.thumbs[thumbChoice]}`,
                    width: 1200,
                    height: 1200,
                },
                style:{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '80vh',
                  objectFit: 'contain',
                },
                imageStyle: {
                  width:'100%',
                  height: 'auto',
                  maxHeight: '80vh',
                  objectFit: 'contain',
                },
                enlargedImagePosition: 'over'
            }} />
            <div className='thumbnails'>
              {
                props.showProduct.thumbs.map((thumb, index)=>(
                  index === thumbChoice ?
                    <div key={index} className="thumbnail" style={{boxShadow: '0px 0px 3px 3px orange'}}>
                      <img src={thumb} height="100%" alt="product thumbnail" onClick={()=>setThumbChoice(index)} />
                    </div>
                  :
                    <div key={index} className="thumbnail">
                      <img src={thumb} height="100%" alt="product thumbnail" onClick={()=>setThumbChoice(index)} />
                    </div>
                ))
              }
            </div>
          </div>
          <div style={{display:'flex', flexDirection: 'column', flex:'1 1 auto', minWidth:'300px', maxWidth:'40%'}}>
              <div style={{fontSize:'calc(15px + 1vmin)', alignSelf:'flex-start', order: '1', fontWeight: 'lighter', width:'100%', marginBottom: '20px'}}>
                <div style={{textAlign: 'left'}}>
                  {props.showProduct.name}
                </div>
                <div style={{textAlign: 'left', fontSize:'calc(5px + 1vmin)', fontWeight:'normal', marginTop:'25px'}}>
                  CAD<span style={{fontSize:'calc(10px + 1vmin)', fontWeight:'bold'}}>${props.showProduct.price}</span><span style={{fontSize:'calc(4px + 1vmin)'}}> as of {props.showProduct.as_of_price_date}</span>
                </div>
                {
                  props.showProduct.was_price !== "" &&
                    <div style={{textAlign: 'left', marginTop:'10px'}}>
                      <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal', color:"red"}}>SAVE </span><span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'bold'}}>{props.showProduct.save}</span> <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal'}}>was: $<del>{props.showProduct.was_price}</del></span>
                    </div>
                }
                <div style={{marginTop:'40px', textAlign:'left',fontSize:'calc(8px + 1vmin)', fontWeight:'normal'}}>
                  <div style={{fontSize:'calc(5px + 1vmin)', fontWeight:'normal', marginBottom:'3px'}}>
                    Quantity
                  </div>
                  <div>
                    <QuantityPicker
                      quantity={quantity}
                      setQuantity={setQuantity}
                      setCartUpdated={props.setCartUpdated}
                    />
                  </div>
                  <div style={{display:'flex'}}>
                    <div style={{marginTop: '20px'}} onClick={event => props.updateCart(props.showProduct.product_id, quantity, props.showProduct.thumbs[0],props.showProduct.name,props.showProduct.price,props.showProduct.as_of_price_date,props.showProduct.length,props.showProduct.width,props.showProduct.depth)}>
                      <AddButton
                        title="ADD TO COLLECTION"
                      />
                    </div>
                    {props.cartUpdated &&
                      <div className='checkAdded'>
                        &#10003;ADDED
                      </div>
                    }
                  </div>
                  {props.cartUpdated &&
                    <div>
                      <div style={{marginTop: '20px'}}>
                        <Link to="/collection">
                          <AddButton
                            title="VIEW COLLECTION"
                          />
                        </Link>
                      </div>
                      <div style={{marginTop: '20px'}}>
                        <Link to="/">
                          <AddButton
                            title="CONTINUE SEARCHING"
                          />
                        </Link>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="noShowProductInfo">
                <div style={{minWidth:'325px',fontSize:'calc(5px + 1vmin)', display:'flex', flexDirection: 'column', alignItems: 'flex-start', flexWrap: 'wrap', borderTop:'1px solid black', marginTop: '0px', paddingTop: '20px'}}>
                  <div style={{marginTop: '20px'}}>
                    <u style={{fontWeight:'bold'}}>Description</u>:
                  </div>
                  <div style={{textAlign: 'left', lineHeight: '1.6'}}>
                    {props.showProduct.description}
                  </div>
                  <div style={{marginTop: '20px'}}>
                    <u style={{fontWeight:'bold'}}>Dimensions</u>:
                  </div>
                  <div style={{textAlign: 'left', lineHeight: '1.6'}}>
                    {props.showProduct.length}"H x {props.showProduct.width}"W {props.showProduct.depth > 0 ? ` x ${props.showProduct.depth}"D` : ""}
                  </div>
                  <div style={{marginTop: '20px'}}>
                    <u style={{fontWeight:'bold'}}>Details</u>:
                  </div>
                  <div style={{textAlign: 'left', lineHeight: '1.6'}}>
                    <ul>
                    {props.showProduct.color !== "" ? (<li key="color">Colour: {props.showProduct.color}</li>) : ""}
                    {
                      props.showProduct.details.map((detail, index)=>{
                        return detail !== "" ?
                          <li key={index}>{detail}</li>
                        :
                          ""
                      })
                    }
                    </ul>
                  </div>
                  {
                    props.showProduct.manufacturer === 'Canadian' ?
                      <div style={{marginTop: '20px'}}>
                        <u style={{fontWeight:'bold'}}>Supplied by a Canadian company</u><img src={maple_leaf} style={{paddingLeft:'10px',width:'20px', height:'20px'}}alt="maple_leaf"/>
                      </div>
                    :
                      ''
                  }
                  <div style={{marginTop: '20px'}}>
                    <u style={{fontWeight:'bold'}}>Notes</u>:
                  </div>
                  <div style={{textAlign: 'left', lineHeight: '1.6'}}>
                    This image is for illustrative purposes only. The colours shown are accurate within the constraints of lighting, photography and the colour accuracy of your screen.<br/>
                    As an Amazon Associate I earn from qualifying purchases.
                  </div>
                </div>
              </div>
          </div>
          <div className="showProductInfo">
          <div style={{minWidth:'325px',fontSize:'calc(10px + 1vmin)', display:'flex', flexDirection: 'column', alignItems: 'flex-start', flexWrap: 'wrap', borderTop:'1px solid black', marginTop: '0px', paddingTop: '20px'}}>
            <div style={{marginTop: '20px'}}>
              <u style={{fontWeight:'bold'}}>Description</u>:
            </div>
            <div style={{textAlign: 'left', lineHeight: '1.6'}}>
              {props.showProduct.description}
            </div>
            <div style={{marginTop: '20px'}}>
              <u style={{fontWeight:'bold'}}>Dimensions</u>:
            </div>
            <div style={{textAlign: 'left', lineHeight: '1.6'}}>
              {props.showProduct.length}"H x {props.showProduct.width}"W {props.showProduct.depth > 0 ? ` x ${props.showProduct.depth}"D` : ""}
            </div>
            <div style={{marginTop: '20px'}}>
              <u style={{fontWeight:'bold'}}>Details</u>:
            </div>
            <div style={{textAlign: 'left', lineHeight: '1.6'}}>
              <ul>
              {props.showProduct.color !== "" ? (<li key="color">Colour: {props.showProduct.color}</li>) : ""}
              {
                props.showProduct.details.map((detail, index)=>{
                  return detail !== "" ?
                    <li key={index}>{detail}</li>
                  :
                    ""
                })
              }
              </ul>
            </div>
            {
              props.showProduct.manufacturer === 'Canadian' ?
                <div style={{marginTop: '20px'}}>
                  <u style={{fontWeight:'bold'}}>Supplied by a Canadian company</u><img src={maple_leaf} style={{paddingLeft:'10px',width:'20px', height:'20px'}}alt="maple_leaf"/>
                </div>
              :
                ''
            }
            <div style={{marginTop: '20px'}}>
              <u style={{fontWeight:'bold'}}>Notes</u>:
            </div>
            <div style={{textAlign: 'left', lineHeight: '1.6'}}>
              This image is for illustrative purposes only. The colours shown are accurate within the constraints of lighting, photography and the colour accuracy of your screen.<br/>
              As an Amazon Associate I earn from qualifying purchases.
            </div>
          </div>
          </div>
        </div>
      :
        <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', width:'100%'}}>
          Please return to previous page and select an item. Thank you.
        </div>
    }
      <div className="similarItems">
      {
        props.showProduct.length > 0 ?
          props.showProduct.buy_together[0] !== "" ?
            <div>
              <div style={{marginBottom:'40px', marginTop:'20px'}}>Complete the Style</div>
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent:'space-evenly'}}>
              <div className="productpagestatic">
                <div>
                  <img src={props.showProduct.thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'200px'}} alt="product pic"/>
                </div>
                <div style={{display:'flex', flexFlow: 'column wrap', fontSize:'calc(8px + 1vmin)'}}>
                  <div>
                    {props.showProduct.name}
                  </div>
                  <div style={{marginTop:'10px'}}>
                    style matches with
                  </div>
                </div>
              </div>
              {
                props.showProduct.buy_together.map((item, index)=>{
                  return(
                    props.allProducts.hasOwnProperty(item.department) ?
                      props.allProducts[item.department].map((product)=>{
                          if(product.hasOwnProperty('product_id')){
                            if(product.product_id === item.product_id){
                              return(
                                <Link to={`/showproduct`} key={product.product_id} onClick={()=>props.setShowProduct(product)} style={{paddingTop:'0px', textDecoration:'none', color:'black'}}>
                                  <div key={index} className="productpage">
                                    <div>
                                      <img src={product.thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'200px'}} alt="product pic"/>
                                    </div>
                                    <div style={{display:'flex', flexFlow: 'column wrap', fontSize:'calc(8px + 1vmin)'}}>
                                      <div>
                                        {product.name}
                                      </div>
                                      <div style={{marginTop:'10px'}}>
                                        CAD<span style={{fontSize:'calc(15px + 1vmin)', fontWeight:'bold'}}>${product.price}</span> <span style={{fontSize:'calc(4px + 1vmin)'}}>as of {product.as_of_price_date}</span>
                                      </div>
                                      {
                                        product.was_price !== "" &&
                                          <div style={{marginTop:'10px'}}>
                                            <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal', color:"red"}}>SAVE </span><span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'bold'}}>{product.save}</span> <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal'}}>was: $<del>{product.was_price}</del></span>
                                          </div>
                                      }
                                    </div>
                                  </div>
                                </Link>
                              )
                            }else{
                              return null
                            }
                          }else if(product.hasOwnProperty('subdepartments')){
                            return(
                              Object.keys(product.subdepartments).map((sub)=>{
                                return(
                                  product.subdepartments[sub].map((subInfo)=>{
                                    if(subInfo.product_id === item.product_id){
                                      return(
                                        <Link to={`/showproduct`} key={subInfo.product_id} onClick={()=>props.setShowProduct(subInfo)} style={{paddingTop:'0px', textDecoration:'none', color:'black'}}>
                                          <div key={index} className="productpage">
                                            <div>
                                              <img src={subInfo.thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'200px'}} alt="product pic"/>
                                            </div>
                                            <div style={{display:'flex', flexFlow: 'column wrap', fontSize:'calc(8px + 1vmin)'}}>
                                              <div>
                                                {subInfo.name}
                                              </div>
                                              <div style={{marginTop:'10px'}}>
                                                CAD<span style={{fontSize:'calc(15px + 1vmin)', fontWeight:'bold'}}>${subInfo.price}</span> <span style={{fontSize:'calc(4px + 1vmin)'}}>as of {subInfo.as_of_price_date}</span>
                                              </div>
                                              {
                                                subInfo.was_price !== "" &&
                                                  <div style={{marginTop:'10px'}}>
                                                    <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal', color:"red"}}>SAVE </span><span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'bold'}}>{subInfo.save}</span> <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal'}}>was: $<del>{subInfo.was_price}</del></span>
                                                  </div>
                                              }
                                            </div>
                                          </div>
                                        </Link>
                                      )
                                    }else{
                                      return null
                                    }
                                  })
                                )
                              })
                            )
                          }else{
                            return null
                          }
                      })
                    :
                      null
                  )
                })
              }
              </div>
            </div>
          :
          props.showProduct.similar_items[0] !== "" ?
            <div>
              <div style={{marginBottom:'40px', marginTop:'20px'}}>Similar Finds</div>
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent:'space-evenly'}}>
              {
                props.showProduct.similar_items.map((item, index)=>{
                  return(
                    props.allProducts.hasOwnProperty(item.department) ?
                      props.allProducts[item.department].map((product)=>{
                        if(product.hasOwnProperty('product_id')){
                          if(product.product_id === item.product_id){
                            return(
                              <Link to={`/showproduct`} key={product.product_id} onClick={()=>props.setShowProduct(product)} style={{paddingTop:'0px', textDecoration:'none', color:'black'}}>
                                <div key={index} className="productpage">
                                  <div>
                                    <img src={product.thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'200px'}} alt="product pic"/>
                                  </div>
                                  <div style={{display:'flex', flexFlow: 'column wrap', fontSize:'calc(8px + 1vmin)'}}>
                                    <div>
                                      {product.name}
                                    </div>
                                    <div style={{marginTop:'10px'}}>
                                      CAD<span style={{fontSize:'calc(15px + 1vmin)', fontWeight:'bold'}}>${product.price}</span> <span style={{fontSize:'calc(4px + 1vmin)'}}>as of {product.as_of_price_date}</span>
                                    </div>
                                    {
                                      product.was_price !== "" &&
                                        <div style={{marginTop:'10px'}}>
                                          <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal', color:"red"}}>SAVE </span><span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'bold'}}>{product.save}</span> <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal'}}>was: $<del>{product.was_price}</del></span>
                                        </div>
                                    }
                                  </div>
                                </div>
                              </Link>
                            )
                          }else{
                            return null
                          }
                        }else if(product.hasOwnProperty('subdepartments')){
                          return(
                            Object.keys(product.subdepartments).map((sub)=>{
                              return(
                                product.subdepartments[sub].map((subInfo)=>{
                                  if(subInfo.product_id === item.product_id){
                                    return(
                                      <Link to={`/showproduct`} key={subInfo.product_id} onClick={()=>props.setShowProduct(subInfo)} style={{paddingTop:'0px', textDecoration:'none', color:'black'}}>
                                        <div key={index} className="productpage">
                                          <div>
                                            <img src={subInfo.thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'200px'}} alt="product pic"/>
                                          </div>
                                          <div style={{display:'flex', flexFlow: 'column wrap', fontSize:'calc(8px + 1vmin)'}}>
                                            <div>
                                              {subInfo.name}
                                            </div>
                                            <div style={{marginTop:'10px'}}>
                                              CAD<span style={{fontSize:'calc(15px + 1vmin)', fontWeight:'bold'}}>${subInfo.price}</span> <span style={{fontSize:'calc(4px + 1vmin)'}}>as of {subInfo.as_of_price_date}</span>
                                            </div>
                                            {
                                              subInfo.was_price !== "" &&
                                                <div style={{marginTop:'10px'}}>
                                                  <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal', color:"red"}}>SAVE </span><span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'bold'}}>{subInfo.save}</span> <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal'}}>was: $<del>{subInfo.was_price}</del></span>
                                                </div>
                                            }
                                          </div>
                                        </div>
                                      </Link>
                                    )
                                  }else{
                                    return null
                                  }
                                })
                              )
                            })
                          )
                        }else{
                          return null
                        }
                      })
                    :
                      null
                  )
                })
              }
              </div>
            </div>
          :
          props.allProducts[`${props.showProduct.departments[0]}`].length > 1 ?
            <div>
              <div style={{marginBottom:'40px', marginTop:'20px'}}>Within the Collection</div>
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent:'space-evenly'}}>
              {
                props.allProducts[`${props.showProduct.departments[0]}`].map((product, index)=>{
                  if(product.name !== props.showProduct.name && index < 4){
                    console.log(product.name)
                    return(
                        <Link to={`/showproduct`} key={product.product_id} onClick={()=>props.setShowProduct(product)} style={{paddingTop:'0px', textDecoration:'none', color:'black'}}>
                          <div key={index} className="productpage">
                            <div>
                              <img src={product.thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'200px'}} alt="product pic"/>
                            </div>
                            <div style={{display:'flex', flexFlow: 'column wrap', fontSize:'calc(8px + 1vmin)'}}>
                              <div>
                                {product.name}
                              </div>
                              <div style={{marginTop:'10px'}}>
                                CAD<span style={{fontSize:'calc(15px + 1vmin)', fontWeight:'bold'}}>${product.price}</span> <span style={{fontSize:'calc(4px + 1vmin)'}}>as of {product.as_of_price_date}</span>
                              </div>
                              {
                                product.was_price !== "" &&
                                  <div style={{marginTop:'10px'}}>
                                    <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal', color:"red"}}>SAVE </span><span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'bold'}}>{product.save}</span> <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal'}}>was: $<del>{product.was_price}</del></span>
                                  </div>
                              }
                            </div>
                          </div>
                        </Link>
                    )
                  }else{
                    return null
                  }
                })
              }
              </div>
            </div>
            :
            props.showProduct.subdepartment !== ''  && props.allProducts[`${props.showProduct.departments[0]}`][0].subdepartments[props.showProduct.subdepartment].length > 1 ?
            <div>
              <div style={{marginBottom:'40px', marginTop:'20px'}}>Within the Collection</div>
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent:'space-evenly'}}>
              {
                props.allProducts[`${props.showProduct.departments[0]}`][0].subdepartments[props.showProduct.subdepartment].map((product, index)=>{
                  if(product.name !== props.showProduct.name && index < 4){
                    console.log(product.name)
                    return(
                      <div key={`${product.name}_key`}>

                          <Link to={`/showproduct`} key={product.product_id} onClick={()=>props.setShowProduct(product)} style={{paddingTop:'0px', textDecoration:'none', color:'black'}}>
                            <div key={index} className="productpage">
                              <div>
                                <img src={product.thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'200px'}} alt="product pic"/>
                              </div>
                              <div style={{display:'flex', flexFlow: 'column wrap', fontSize:'calc(8px + 1vmin)'}}>
                                <div>
                                  {product.name}
                                </div>
                                <div style={{marginTop:'10px'}}>
                                  CAD<span style={{fontSize:'calc(15px + 1vmin)', fontWeight:'bold'}}>${product.price}</span> <span style={{fontSize:'calc(4px + 1vmin)'}}>as of {product.as_of_price_date}</span>
                                </div>
                                {
                                  product.was_price !== "" &&
                                    <div style={{marginTop:'10px'}}>
                                      <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal', color:"red"}}>SAVE </span><span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'bold'}}>{product.save}</span> <span style={{fontSize:'calc(8px + 1vmin)', fontWeight:'normal'}}>was: $<del>{product.was_price}</del></span>
                                    </div>
                                }
                              </div>
                            </div>
                          </Link>

                      </div>
                    )
                  }else{
                    return null
                  }
                })
              }
              </div>
            </div>
          :
            ''
        :
          ''
      }
      </div>
    </div>
  );
};

export default ShowProduct;

//<div key={index}>{product.name}</div>

/* <img src={props.showProduct.thumbs[0]} style={{position:'relative', maxWidth:'90%', minHeight:'400px', border:'1px solid red'}} alt="product pic"/> */

/*
<div>
  <h2>{props.department}</h2>
  <ul>{
      props.currentProducts.length > 0 ?
        props.currentProducts.map((product, index)=> <li key={product.product_id}><a href={product.href}>{product.name} - {product.product_id}</a><br/><img src={product.thumbs[0]} width="100" height="100" alt={product.name}/><br/>~ {product.price} <code>as of {product.as_of_price_date}</code><br/>Quantity:<input type="number" id={product.product_id} onChange={event => props.updateCart(product.product_id, event.target.value)} defaultValue={props.cart.find(elem => elem.ASIN === product.product_id) !== undefined ? props.cart.find(elem => elem.ASIN === product.product_id).Quantity : 0} /><br/><br/></li>)
      :
        <div />
  }</ul>
</div>
*/
