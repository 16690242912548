import React from 'react';

import '../css/addbutton.css';

const AddButton = (props)=>{
  return(
    <div className="button">
      {props.title}
    </div>
  );
};

export default AddButton;
