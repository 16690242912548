import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import '../css/footer.css';

const Footer = (props)=>{
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  });


  let top = ()=>{
    window.scrollTo(0, 0);
  };

  return(
    <div className="mainFooter">
      {
        scrollPosition > 100 ?
          <div onClick={()=>top()} className="top">
            Back to Top ^
          </div>
        :
          ''
      }
      <div className="borderFooter">
        <div className="subscribeFooter">
          <div>
            <div className="emailTitle">
              Stay up to date...
            </div>
            <div className="emailSubTitle">
              Sign up for emails to be the first to know about our latest finds
            </div>
          </div>
          <div style={{flexGrow:'2', maxWidth: '350px', minWidth: '250px'}}>
            <div id="mc_embed_signup" className="text-normal width-80-percent">
               <form action="https://marshawheelerdesign.us14.list-manage.com/subscribe/post?u=70f5917f842e7f76ac2b3fb91&amp;id=ef32e2d633&SIGNUP=homepage&MEDIA=webpage" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
                  <div id="mc_embed_signup_scroll">
                    <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="ENTER YOUR EMAIL ADDRESS" style={{marginBottom:'20px'}} required />
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_70f5917f842e7f76ac2b3fb91_ef32e2d633" tabIndex="-1" /></div>
                    <div><input type="submit" value="SUBMIT" name="subscribe" id="mc-embedded-subscribe" className="button" style={{width:'100%'}}/></div>
                   </div>
               </form>
           </div>
          </div>
        </div>
      </div>
      <div className="noBorderFooter">
        <div className="categoryFooter">
          DEPARTMENTS
          {
            props.departments.map((department,index)=>(
              (department === 'new arrivals') &&
                <div className="itemFooter" key='new arrivals'><Link to={`/new arrivals`} className="title-link">new finds</Link></div>
            ))
          }
          {
            props.departments.map((department, index)=>{
                if(department === 'new arrivals'){
                  return ''
                }else{
                  return <div className="itemFooter" key={department}><Link to={`/${department}`} className="title-link">{department.replace('_', ' ')}</Link></div>
                }

            })
          }
        </div>
        <div className="categoryFooter">
          CONTACT US
          <div className="itemFooter">
            (905) 242-9398
          </div>
          <div className="itemFooter">
            marshawheelerdesign@gmail.com
          </div>
          <div className="itemFooter">
            Mon-Fri 9am to 6:30pm
          </div>
          <div className="itemFooter">
            All times Eastern
          </div>
        </div>
        <div className="categoryFooter">
          RESOURCES
          <div className="itemFooter">
            <a href="https://www.marshawheelerdesign.com/#servicesdesktop" className="title-link" style={{marginRight:'100px'}} target="_blank" rel="noopener noreferrer">Interior Styling Services</a>
          </div>
          <div className="itemFooter">
            <a href="https://marshawheelerdesign.wordpress.com/" className="title-link" style={{marginRight:'100px'}} target="_blank" rel="noopener noreferrer">Blog</a>
          </div>
          <div className="itemFooter">
            <Link to={'/privacy_policy'} className="title-link">Privacy Policy</Link>
          </div>
          <div className="itemFooter">
            <Link to={'/terms_of_use'} className="title-link">Terms of Use</Link>
          </div>
        </div>
        <div className="categoryFooter">
          FOLLOW US
          <div className="itemFooter">
            <div className="iconcontainer text-center valign-top">
                <a href="https://www.facebook.com/marshawheelerdesign" target="_blank" title="Facebook" rel="noopener noreferrer">
                    <div className="bubble_icon">
                        <i className="icon fa fa-facebook"></i>
                    </div>
                </a>
                <a href="https://www.instagram.com/marshawheelerdesign/" target="_blank" title="Instagram" rel="noopener noreferrer">
                    <div className="bubble_icon">
                        <i className="icon fa fa-instagram fa-lg"></i>
                    </div>
                </a>
                <a href="https://twitter.com/marshawheeler00" target="_blank" title="Twitter" rel="noopener noreferrer">
                    <div className="bubble_icon">
                        <i className="icon fa fa-twitter fa-lg"></i>
                    </div>
                </a>
                <a href="https://marshawheelerdesign.wordpress.com/" target="_blank" title="Blog" rel="noopener noreferrer">
                    <div className="bubble_icon">
                        <i className="icon fa fa-wordpress fa-lg"></i>
                    </div>
                </a>
                <a href="https://www.houzz.com/pro/marshawheelerdesign/marsha-wheeler-design" target="_blank" title="Houzz" rel="noopener noreferrer">
                    <div className="bubble_icon">
                        <i className="icon fa fa-houzz fa-lg"></i>
                    </div>
                </a>
                <a href="https://www.pinterest.ca/marshalwheeler/" target="_blank" title="Pinterest" rel="noopener noreferrer">
                    <div className="bubble_icon">
                        <i className="icon fa fa-pinterest-p fa-lg"></i>
                    </div>
                </a>
            </div>
          </div>
        </div>

      </div>
      <div className="noBorderFooter">
        <div className="categoryFooter" style={{textAlign:'left'}}>
          Our Commitment to Accessibility
          <div className="itemFooter" style={{lineHeight: '1.6'}}>
            We strive to make MW Style Finds experience accessible and user-friendly. If you notice any content, feature or functionality that you believe is not fully accessible to all people, please email us at marshawheelerdesign@gmail.com. Please provide a description of the specific feature you feel is not fully accessible or a suggestion for improvement.
          </div>
        </div>
      </div>
      <div className="noBorderFooter">
        <div className="categoryFooter" style={{textAlign:'left'}}>
          <div className="itemFooter" style={{lineHeight: '1.6'}}>
            Content copyright {new Date().getFullYear()}. MARSHAWHEELERDESIGN.COM<div>All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer;
