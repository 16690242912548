import React from 'react';
import { Link } from "react-router-dom";

import '../css/privacypolicy.css';

const TermsOfUse = ()=>{
  return (
    <div className="resource_main">
      <div className="resource_title">Terms of Use</div>
      <div className="resource_paragraph">
        This Website (this"Site") is owned and operated by Marsha Wheeler Design. Your use of this Site is conditioned on your acceptance of these Terms of Use (these "Terms"). By accessing, using or browsing this Site, you acknowledge and agree to be bound to these Terms. If you do not agree to these Terms or if you do not agree with our <Link to={'/privacy_policy'} className="title-link"><u>Privacy Policy</u></Link>, please do not use this Site. Any use of this Site inconsistent with these Terms is deemed unauthorized access. We reserve the right to make changes to these Terms at any time, without notice to you, by posting changes on this Site. Check back from time to time to ensure you are aware of any updates or changes to these Terms.
      </div>
      <div className="resource_sub_title">
        Communications
      </div>
      <div className="resource_paragraph">
        When you communicate to us through our forms, email, text message, phone call or any other means, you consent to receive communications from us. Both parties have the right to retain copies of these communications.
      </div>
      <div className="resource_sub_title">
        Amazon Associate
      </div>
      <div className="resource_paragraph">
        As an Amazon Associate I earn from qualifying purchases. MW Style Finds recommends products from the Amazon site, which are researched and/or used by Marsha Wheeler Design. All purchases based off of these style recommendations are between Amazon and the purchaser.
      </div>
      <div className="resource_sub_title">
        Pricing
      </div>
      <div className="resource_paragraph">
        Prices indicated on MW Style Finds are updated once every 24hrs and listed "as of" the last known price by Marsha Wheeler Design. We do not make any guarantee of the price.
      </div>
      <div className="resource_sub_title">
        Intellectual Property
      </div>
      <div className="resource_paragraph">
        Marsha Wheeler Design owns the rights to the title and interest in this Site, MW logo, code, the look and feel, selection and arrangement, home page carousel images, design and organization of this Site. These rights are protected by Canadian and international copyright laws.
      </div>
      <div className="resource_sub_title">
        Copyright Complaint Policy
      </div>
      <div className="resource_paragraph">
        Infringement Notification: If you believe in good faith that materials hosted by us infringe your copyright, please provide the written information requested below. Please provide the following information in the following order (including Paragraph Numbers):
        <ol>
          <li>A clear identification of the material you claim is infringing the copyrighted work, and information that will allow us to locate that material on the Site, such as a link to the infringing material.</li>
          <li>Your contact information so that we can reply to your complaint, preferably including an email address and a telephone number.</li>
          <li>Include the following statement: "I have a good faith belief that the material that is claimed as copyright infringement is not authorized by the copyright owner, its agent, or the law."</li>
          <li>Include the following statement: "I swear, under penalty of perjury, that the information in the notification is accurate and I am the copyright owner, or am authorized to act on behalf of the owner, of an exclusive right that is allegedly infringed."</li>
          <li>The notice must be signed by the person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. Notices of claims of copyright infringement on this Site should be emailed to: marshawheelerdesign@gmail.com</li>
        </ol>
      </div>
    </div>
  )
};

export default TermsOfUse;
