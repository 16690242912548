import React, { useEffect } from 'react';
import Mark from 'mark.js';
import { Link } from "react-router-dom";

import '../css/searchpage.css';

const SearchPage = (props)=>{

/// pass products (how ever it is laid out) as props (app.js, titlebar.js (holds the searchbar), this searchpage)
// app.js creates index from products and sets up lunr index (put into useEffect function that gets the products from firebase)
// titlebar.js uses lunr index to search the indexed products and return the found items in order of score
// searchpage.js aligns the search result id's from titlebar to our actual products so they can be displayed
// https://rahmanfadhil.com/lunr/ and lunrjs.com

  const searchProducts = (searchResults)=>{
    console.log('searching products......................................')
    console.log(searchResults)
    return searchResults.map((item)=>{
      return props.lunrProducts.find((product)=> item.ref === product.product_id);
    });
  };

  useEffect(()=>{
    if(props.searchValue !== ""){
      let markInfo = [];
      //markInfo.push(props.searchValue);
      props.searchResults.forEach((result, index)=>{
        let wordsArray = Object.keys(result.matchData.metadata);
        if(wordsArray.length > 1){
          wordsArray.forEach((word)=>{
            if(word !== undefined){
              markInfo.push(word);
            }
          })
        }else{
          if(wordsArray[0] !== undefined){
            markInfo.push(wordsArray[0]);
          }
        }
      });
      console.log(markInfo);
      let markInstance = new Mark(document.querySelector("#search-node"));
      markInstance.unmark({
      	done: function(){
        	//markInstance.mark(props.searchValue);
          markInstance.mark(markInfo);
        }
      });
    }
  }); // no array - runs after each and every render, but not linked to a specific prop.  Empty array runs after render but only once.

  return(
    <div className='searchBody'>
      <div className="searchTitle">
        <div>
          SEARCH
        </div>
        <div className='searchSubtitle'>
        {props.searchValue !== "" ? props.searchValue : 'please start typing...'}
        </div>
      </div>
      <div className='searchMain' id="search-node">
        <div className="search_items">
          {
            props.searchValue !== ""  && props.searchResults.length > 0 ?
              searchProducts(props.searchResults).map((product, index)=>(
                <Link to={`/showproduct`} key={product.product_id} className="search_item_info" onClick={()=>props.setShowProduct(product)} style={{textDecoration:'none', color:'black'}}>
                  <div className='search_item_image'><img src={product.thumbs[0]} style={{width:'100%'}} alt="product pic"/></div>
                    <div style={{paddingLeft: '20px', paddingTop: '0px'}}>
                      <div style={{fontWeight:'lighter'}}>{product.name}</div>
                      <div style={{textAlign: 'left', fontSize:'calc(6px + 1vmin)', fontWeight:'normal', marginTop:'5px'}}>{product.length}"H x {product.width}"W {product.depth > 0 ? ` x ${product.depth}"D` : ""}</div>
                      <div className="search_item_description">{product.description}</div>
                      <div style={{textAlign: 'left', lineHeight: '1.6', fontSize: 'calc(8px + 1vmin)'}}>
                        <ul>
                        {product.departments[0] !== "" ? (<li key="department">Department: {product.departments[0]}</li>) : ""}
                        {product.color !== "" ? (<li key="color">Colour: {product.color}</li>) : ""}
                        {                            product.details.map((detail, index)=>{
                            return detail !== "" ?
                              <li key={index}>{detail}</li>
                            :
                              ""
                          })
                        }
                        </ul>
                      </div>
                  </div>
                </Link>
            ))
            :
              <div className="searchSubtitle">No results</div>
          }
        </div>
      </div>
    </div>
  );
};

export default SearchPage

/*
<div style={{textAlign:'left', margin:'20px'}}>
  <div style={{marginTop:'50px'}}>
    {`Searching for.... ${props.searchValue}`}
  </div>
  <div id="search-node" style={{marginTop:'50px', fontSize:'16px'}}>
    Results:
    <ol>
      {
        props.searchValue !== ""  && props.searchResults.length > 0 ?
          searchProducts(props.searchResults).map((product, index)=>(
              <div key={product.product_id}>
                <li key={index}>Name: {product.name} => <div className='item_image'><img src={product.thumbs[0]} style={{width:'100%'}} alt="product pic"/></div>Description: {product.description} => Department: {product.departments.map(department=> `${department}  `)}</li>
                <ul>
                  {
                    product.details.map((detail, index2)=>(
                      <li key={`${product.product_id}_${index2}`}>{detail}</li>
                    ))
                  }
                </ul>
              </div>
          ))
        :
          <div>No results found</div>
      }
    </ol>
  </div>
</div>
*/
