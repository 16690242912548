import React from 'react';
import { Link } from "react-router-dom";

import '../css/titlebar.css';
import cart from '../images/cart.svg';

const CartLogo = (props)=>{

  const getCartCount = (currentCart)=>{
    console.log(currentCart);
    let cartCount = 0;
    if(currentCart.length > 0){
      //return currentCart.reduce((acc, value) => parseInt(acc.Quantity) + parseInt(value.Quantity));
      currentCart.forEach((item)=>{
        cartCount += parseInt(item.Quantity);
      });
      return cartCount;
    }else{
      return '';
    }

  };

  return(
    <div className="CartLogo">
      <Link to="/collection"><img src={cart} alt="logo" className="cart" /></Link>
      <div className="cartNumber" style={{fontSize: getCartCount(props.cart) < 10 ? 18 : 16}}><Link to="/collection" style={{textDecoration:"none"}}>{getCartCount(props.cart)}</Link></div>
    </div>
  );
};

export default CartLogo;
