import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

import Departments from './Departments.js';
import AddButton from './AddButton.js';

import two from '../images/carousel/2.2.jpg';
import two_66 from '../images/carousel/two_66.jpg';
import blog from '../images/carousel/holidaylookbook.jpg';
//import logo from '../images/carousel/logo_large_cropped.jpg';
import logo from '../images/carousel/mwccsp_2.jpg';
import logo_2 from '../images/carousel/mwccsp.jpg';

import '../css/home.css';

const Home = (props)=>{

  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [currentSlide, updateCurrentSlide] = useState(0);

  const updateWindow = ()=>{
    setWindowInnerWidth(window.innerWidth);
  };

  window.addEventListener('resize', updateWindow);

  const getConfigurableProps = () => ({
    autoPlay: true,
    stopOnHover: true,
    interval: 6000,
    transitionTime: 750,
    showArrows: true,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: true,
    thumbWidth: 100,
    selectedItem: 0,
    swipeScrollTolerance: 5,
  });

  return(
    <div>
    {
      windowInnerWidth > 1200 ? //////
        <Carousel {...getConfigurableProps()} onChange={index=> updateCurrentSlide(index)}>
          <div className="carouselContainer">
            <img src={two} className="cover" alt="two" />
            <div style={{display:'flex', flexDirection:'row', position:'absolute', top:'50%', left:'5%', transform:'translate(0,-50%)', backgroundColor:'white', maxWidth: '800px', maxHeight:'400px', width:'45%', height:'90%', opacity:'0.9', borderRadius:'0px', padding:'5px'}}>
              <img src={logo} alt="logo" style={{width:'50%', marginRight:'30px'}}/>
              <div style={{fontSize:'22px', marginTop:'10px', marginBottom:'20px', alignSelf:'center'}}>I created this boutique site to narrow down the overwhelming selection of decor pieces to suit the style of my clients.<br/><br/>These are my hand picked style finds to add the final touches to your space.</div>
            </div>
          </div>
          <div className="carouselContainer">
            <img src={two_66} className="cover" alt="two_66" />
            <div style={{position:'absolute', top:'10%', left:'5%', backgroundColor:'white', width:'320px', height:'200px', opacity:'0.9', borderRadius:'0px', padding:'20px'}}>
              <div>Pillows, Pillows, Pillows!</div>
              <div style={{fontSize:'22px', marginTop:'10px', marginBottom:'20px'}}>Texture brings room design to the next level</div>
              <Link to="/pillows"><AddButton title="OUR FAVOURITES" /></Link>
            </div>
          </div>
          <div className="carouselContainer">
            <img src={blog} className="cover" alt="blog" />
            <div style={{position:'absolute', top:'10%', left:'5%', backgroundColor:'#CC5A2A', color:'white', width:'470px', height:'210px', opacity:'0.9', borderRadius:'0px', padding:'20px'}}>
              <div>Holiday Look Book</div>
              <div style={{fontSize:'22px', marginTop:'10px', marginBottom:'20px'}}>Take a gander through these holiday collections for your last minute<div>“perfect piece”</div></div>
              <a href="https://marshawheelerdesign.wordpress.com/2021/12/12/mw-style-finds-holiday-look-books/" rel="noopener noreferrer" target="_blank"><AddButton title="INSPIRATION BOARDS" /></a>
            </div>
          </div>
        </Carousel>
      :
      windowInnerWidth > 650 ?
        <div>
          <Carousel {...getConfigurableProps()} onChange={index=> updateCurrentSlide(index)}>
            <div className="carouselContainer">
              <img src={logo_2} className="cover" alt="logo_2"/>
            </div>
            <div className="carouselContainer">
              <img src={two_66} className="cover" alt="two_66" />
            </div>
            <div className="carouselContainer">
              <img src={blog} className="cover" alt="blog" />
            </div>
          </Carousel>
          {
            currentSlide === 0 ?
              <div style={{paddingTop:'20px', paddingLeft:'50px', paddingRight:'50px'}}>
                <div style={{fontSize:'16px'}}>MW STYLE FINDS</div>
                <div style={{fontSize:'22px', marginTop:'10px', marginBottom:'20px', alignSelf:'center', textAlign:'justify'}}>I have created this boutique site to narrow down the overwhelming selection of decor pieces to suit the style of my clients.<br/><br/>These are my hand picked style finds to add the final touches to your space.</div>
              </div>
            :
            currentSlide === 1 ?
              <div style={{padding:'20px'}}>
                <div style={{fontSize:'16px'}}>Pillows, Pillows, Pillows!</div>
                <div style={{fontSize:'22px', marginTop:'10px', marginBottom:'20px'}}>Texture brings room design to the next level</div>
                <Link to="/pillows"><AddButton title="OUR FAVOURITES" /></Link>
              </div>
            :
            currentSlide === 2 ?
              <div style={{padding:'20px'}}>
                <div style={{fontSize:'16px'}}>Holiday Look Book</div>
                <div style={{fontSize:'22px', marginTop:'10px', marginBottom:'20px'}}>Take a gander through these holiday collections for your last minute<div>“perfect piece”</div></div>
                <a href="https://marshawheelerdesign.wordpress.com/2021/12/12/mw-style-finds-holiday-look-books/" rel="noopener noreferrer" target="_blank"><AddButton title="INSPIRATION BOARDS" /></a>
              </div>
            :
            ''
          }
        </div>
      :
      <div>
        <Carousel {...getConfigurableProps()} onChange={index=> updateCurrentSlide(index)}>
          <div className="carouselContainer">
            <img src={logo_2} className="cover" alt="logo_2"/>
          </div>
          <div className="carouselContainer">
            <img src={two_66} className="cover" alt="two_66" />
          </div>
          <div className="carouselContainer">
            <img src={blog} className="cover" alt="blog" />
          </div>
        </Carousel>
        {
          currentSlide === 0 ?
            <div style={{padding:'20px'}}>
              <div style={{fontSize:'16px'}}>MW STYLE FINDS</div>
              <div style={{fontSize:'16px', marginTop:'10px', marginBottom:'0px', alignSelf:'center', textAlign:'justify'}}>I have created this boutique site to narrow down the overwhelming selection of decor pieces to suit the style of my clients.<br/><br/>These are my hand picked style finds to add the final touches to your space.</div>
            </div>
          :
          currentSlide === 1 ?
            <div style={{padding:'20px'}}>
              <div style={{fontSize:'16px'}}>Pillows, Pillows, Pillows!</div>
              <div style={{fontSize:'22px', marginTop:'10px', marginBottom:'20px'}}>Texture brings room design to the next level</div>
              <Link to="/pillows"><AddButton title="OUR FAVOURITES" /></Link>
            </div>
          :
          currentSlide === 2 ?
              <div style={{padding:'20px'}}>
                <div style={{fontSize:'16px'}}>Holiday Look Book</div>
                <div style={{fontSize:'22px', marginTop:'10px', marginBottom:'20px'}}>Take a gander through these holiday collections for your last minute “perfect piece”</div>
                <a href="https://marshawheelerdesign.wordpress.com/2021/12/12/mw-style-finds-holiday-look-books/" rel="noopener noreferrer" target="_blank"><AddButton title="INSPIRATION BOARDS" /></a>
              </div>
            :
          ''
        }
      </div>
    }
        <div style={{marginTop:'50px', fontWeight:'lighter'}}>
          {props.departmentTitle.toUpperCase()}
        </div>
      <div>
        <Departments info={props}/>
      </div>
    </div>
  );
};

/*

*/

export default Home;
