import React from 'react';
import { Link } from "react-router-dom";

import '../css/departments.css';

const Departments = (props)=>{
  console.log(props.info.allProducts.products['lighting'][0]['subdepartments']);
  const getFirstSubdepartment = (departmentProps)=>{
    console.log(departmentProps);
    return Object.keys(departmentProps)[0];
  };
  return(
    <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center'}}>
      {
        props.info.departments.map((department, index)=>(
          (department !== 'new arrivals') &&
            <Link to={`/${department}`} key={department} style={department !== 'top finds' ? {paddingTop:'0px', textDecoration:'none', color:'black'} : {paddingTop:'0px', textDecoration:'none', color:'red'}}>
              <div key={index} className="department">
                <div>
                  {
                    (!props.info.allProducts.products[department][0].hasOwnProperty('subdepartments')) ?
                      <img src={props.info.allProducts.products[department][0].thumbs[0]} style={{position:'relative', width:'80%', maxHeight:'350px', minHeight:'250px'}} alt="department pic"/>
                    :
                      <img src={props.info.allProducts.products[department][0]['subdepartments'][getFirstSubdepartment(props.info.allProducts.products[department][0]['subdepartments'])][0].thumbs[0]} style={{position:'relative', maxWidth:'90%', maxHeight:'350px', minHeight:'250px'}} alt="department pic"/>
                  }
                </div>
                <div>
                  {department.replace('_', ' ')}
                </div>
              </div>
            </Link>
        ))
      }
    </div>
  );
};

export default Departments;
