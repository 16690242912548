import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const BlankProductPage = ()=>{
  return(
      <div>
        <div style={{marginTop:'30px', marginBottom:'10px'}}>Collecting the best styles....</div>
        <Loader type="ThreeDots" color="#D9D2C3" height={80} width={80} />
      </div>
  );
};
//<div style={{fontWeight:'lighter', marginTop:'30px'}}>Welcome to MW Style Finds</div>
export default BlankProductPage;
