import React from 'react';

import '../css/quantitypicker.css';

const QuantityPicker = (props)=>{

  const decrementQuantity = ()=>{
    let qty = props.quantity;
    qty--;
    if(qty > 0){
      props.setQuantity(qty);
      props.setCartUpdated(false);
    }
  };

  const incrementQuantity = ()=>{
    let qty = props.quantity;
    qty++;
    props.setQuantity(qty);
    props.setCartUpdated(false);
  };

  return(
    <div style={{display:'flex'}}>
      <div className="decrement" onClick={()=>decrementQuantity()}>-</div>
      <div className="quantity">{props.quantity}</div>
      <div className="increment" onClick={()=>incrementQuantity()}>+</div>
    </div>
  );
};

export default QuantityPicker;
