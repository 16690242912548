import React, { useState, useEffect } from 'react';
import { db, auth } from "./firebase";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import lunr from "lunr";

import ScrollToTop from './Components/ScrollToTop.js';
import Home from './Components/Home.js';
import Subdepartment from './Components/Subdepartment.js';
import ProductPage from './Components/ProductPage.js';
import ShowProduct from './Components/ShowProduct.js';
import ShowProductLink from './Components/ShowProductLink.js';
import BlankProductPage from './Components/BlankProductPage.js';
import Titlebar from './Components/Titlebar.js';
import Cart from './Components/Cart.js';
import SearchPage from './Components/SearchPage.js';
import Footer from './Components/Footer.js';
import PrivacyPolicy from './Components/PrivacyPolicy.js';
import TermsOfUse from './Components/TermsOfUse.js';
import './css/App.css';
import mwlogo from './images/logo_large_cropped.jpg';

//import data from './products.json'; // this represents fetch in useEffect (cannot use fetch to hostinger server while testing on local host CORS issue)

function App() {
  const [cart, setCart] = useState([]);
  const [cartUpdated, setCartUpdated] = useState(false);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [showProduct, setShowProduct] = useState({});
  const [currentSelection, setCurrentSelection] = useState('');
  const [allProducts, setAllProducts] = useState({});
  const [subDepartments, setSubDepartments] = useState({});
  const [motherDepartment, setMotherDepartment] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]); // write algorithm around allProducts that will populate the search results with product name, description and link to show product
  const [lunrIndex, setLunrIndex] = useState([]);
  const [lunrProducts, setLunrProducts] = useState([]);


  const [shopByDepartment] = useState(['Style by Department', 'Style by Design', 'Style For Every Room', 'Objects of Beauty', 'Dress up your space', 'Finding your Style', 'Discover Your Style',"What I'm Crushing On", "What We're Loving", 'Refresh your space', 'Take a Tour']);
  const [randomNumber] = useState(Math.floor(Math.random() * shopByDepartment.length));

  const [departmentQuotes] = useState({
    'artwork': 'Artwork speaks volumes about a person. Show your style by showcasing your "unique to you" artwork',
    'baskets': 'Proper storage is essential to home organization. Decorative baskets are not only functional, but they store your items in style',
    'decor accessories': 'Accessories add that final layer of style to each room',
    'florals & greenery': 'Add nature inspired elements into your home to evoke calmness, tranquility and peace',
    'lighting': 'Introduce overhead and task lighting to create a certain mood for each space',
    'area rugs': 'Liven up your room by laying a foundation and anchoring your space with area rugs',
    'pillows': 'Add softness to your space with plush pillows',
    'throws': 'Create a cozy corner by introducing textured throw blankets',
    'entertaining essentials': 'Wow your guests this season with style worthy entertaining essentials',
    'bathroom essentials': 'Add style to function on your everyday bathroom essentials',
    'bedding': 'Create a bedroom oasis, by crawling under covers of comfort and beauty'
  });

  // amazon cart link
  //https://www.amazon.ca/gp/aws/cart/add.html?ASIN.1=B07JKJSJHY&Quantity.1=1&ASIN.2=B07JXF7251&Quantity.2=2

  // load once from server - products list
  useEffect(()=>{
    console.log('useEffect called - get products');
    //setProducts(data.products);

    auth.signInAnonymously().then(()=>{
      console.log('connection to database complete.');
    }).catch((error)=>{
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(`Error getting anonymous user: ${errorCode} -> ${errorMessage}`);
    });

    auth.onAuthStateChanged((user)=>{
      if(user){
        let firebaseData = {"products":{}};
        let firebaseDepartments = {};
        let productsForSearch = [];
        db.collection('website_data').get().then(querySnapshot=>{
          querySnapshot.docs.forEach((doc)=>{
            console.log(doc.id);
            console.log(doc.data());
            if(doc.id === 'products'){
              firebaseData.products = doc.data();
              console.log(firebaseData);
              setAllProducts(firebaseData);
            }
            if(doc.id === 'list_of_subs'){
              firebaseDepartments = doc.data().subDepartments;
              setSubDepartments(firebaseDepartments);
            }
            if(doc.id === 'list_for_searches'){
              productsForSearch = doc.data().productsForSearch;
              setLunrProducts(productsForSearch);
            }
          });
        }).catch((error)=>{
          console.log('Error getting products: ' + error);
        });
        
      }else{
        console.log('Lost database connection.')
      }
    });
    // would fetch from server.
    /*fetch('https://www.surfacetoairmanagement.com/marshawheelerdesign/api2/getproductlist.php?category=all')
                .then(function (response) {
                  return response.json();
                })
                .then(function (data) {
                  console.log(data);
                  updateProducts(data.products);
                });*/

  }, []);

  useEffect(()=>{
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // algorithm to search props.allProducts (departments, subdepartments, product names, product descriptions, and variations of search words) - using lunr
    //console.log(lunrProducts);
    setLunrIndex(lunr(function () {
        console.log('index called..................................');
        this.ref("product_id");

        // issue fields because of arrays - needs to be build properly array to strings
        /*
          details
        */

        this.field("name");
        this.field("description");
        this.field("detail1");
        this.field("detail2");
        this.field("detail3");
        this.field("detail4");
        this.field("department0");
        this.field("department1");
        this.field("department2");
        this.field("detail0");
        this.field("subedepartment");
        this.field("color");
        this.field("brand");
        this.field("manufacturer");
        this.metadataWhitelist = ['position'];

          /////////////////////////////////////// create index from products
        for (let i = 0; i < lunrProducts.length; i++) {
          let adjusted = lunrProducts[i];
          adjusted.departments.forEach((department,index)=>{
            adjusted[`department${index}`] = department;
          });
          adjusted.details.forEach((detail,index)=>{
            adjusted[`detail${index}`] = detail;
          });
          //console.log(adjusted);
          this.add(adjusted);
        }
      }));
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }, [lunrProducts]);

  useEffect(()=>{
    // set cart
    if(cart.length === 0){
      if(localStorage.getItem('cart') !== null && JSON.parse(localStorage.getItem('cart')).length > 0){
        console.log('useEffect - update cart from localStorage');
        setCart(JSON.parse(localStorage.getItem('cart')));
        JSON.parse(localStorage.getItem('cart')).forEach((item)=>{
          // set value for input - id = ASIN
        });
      }
    }
  }, [cart]);

  useEffect(()=>{
    if(allProducts.hasOwnProperty('products')){
      if(allProducts.products[currentSelection] !== undefined){
        setCurrentProducts(allProducts.products[currentSelection]); // main department
      }else{
        // sub department
        if(allProducts.products.hasOwnProperty(motherDepartment)){
          if(allProducts.products[motherDepartment][0].hasOwnProperty('subdepartments')){
            setCurrentProducts(allProducts.products[motherDepartment][0].subdepartments[currentSelection]);
          }
        }
      }
    }
  }, [currentSelection, motherDepartment, allProducts]);

  //localStorage.clear();
  const saveCartDatabase = ()=>{
    console.log('save to database');
    console.log(cart);
    db.collection('carts').add({
      'timestamp': Date.now(),
      cart
    }).then((docRef)=>{
      console.log('Document written with id: ', docRef.id);
    }).catch((error)=>{
      console.log('Error writting document: ', error);
    });
    //cart.map((item, index)=>{
    //  console.log(`ASIN: ${item.ASIN} Quantity: ${item.Quantity}`);
    //});
  };

  const setCartItem = (ASIN, quantity, imageLink)=>{
    let tempCart = [...cart];
    //let found = false;
    tempCart.forEach((item, index)=>{
      if(item.ASIN === ASIN){
        tempCart[index].Quantity = quantity;
        //found = true;
        setCartUpdated(true);
        if(quantity === 0){
          console.log('splice');
          tempCart.splice(index, 1);
        }
      }
    });
    setCart(tempCart);
    localStorage.setItem('cart',JSON.stringify(tempCart));
  };

  const updateCart = (ASIN, quantity, imageLink, name, price, as_of_price_date,length,width,depth)=>{
    let tempCart = [...cart];
    let found = false;
    tempCart.forEach((item, index)=>{
      if(item.ASIN === ASIN){
        tempCart[index].Quantity += quantity;
        found = true;
        setCartUpdated(true);
        if(quantity === "0"){
          console.log('splice');
          tempCart.splice(index, 1);
        }
      }
    });
    if(!found){
      tempCart.push({'ASIN': ASIN, 'Quantity': quantity, 'ImageLink': imageLink, 'Name': name, 'Price': price, 'As_Of_Price_Date': as_of_price_date, 'Length': length, 'Width': width, 'Depth': depth});
      setCartUpdated(true);
    }
    setCart(tempCart);
    localStorage.setItem('cart',JSON.stringify(tempCart));
  };

  const clearCart = ()=>{
    let tempCart = [...cart];
    tempCart = [];
    setCart(tempCart);
    localStorage.setItem('cart', JSON.stringify(tempCart));
  };
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Titlebar
          cart={cart}
          departments={allProducts.hasOwnProperty('products') ? Object.keys(allProducts.products).sort() : []}
          subDepartments={subDepartments}
          setCurrentSelection={setCurrentSelection}
          allProductsForSearch={allProducts}
          setSearchValue={setSearchValue}
          setSearchResults={setSearchResults}
          idx={lunrIndex}
        />
        <header className="App-header">
          <Switch>
            {
              allProducts.hasOwnProperty('products') ? Object.keys(subDepartments).map((subdepartment, index)=>(
                <Route path={`/${subdepartment}`} key={subdepartment}>
                  <Subdepartment
                    department={subdepartment}
                    subdepartments={allProducts.hasOwnProperty('products') ? allProducts.products[subdepartment][0].subdepartments : ''}
                    departmentQuotes={departmentQuotes}
                  />
                </Route>
              ))
              :
              <BlankProductPage />
            }
            {
              allProducts.hasOwnProperty('products') ? Object.keys(subDepartments).map((subdepartment, index)=>(
                subdepartment.includes('&') ?
                  <Route path={`/${subdepartment.replace('&', '%26')}`} key={subdepartment.replace('&', '%26')}>
                    <Subdepartment
                      department={subdepartment}
                      subdepartments={allProducts.hasOwnProperty('products') ? allProducts.products[subdepartment][0].subdepartments : ''}
                      departmentQuotes={departmentQuotes}
                    />
                  </Route>
                  :''
              ))
              :
              <BlankProductPage />
            }
            {
                allProducts.hasOwnProperty('products') ? Object.keys(allProducts.products).map((department, index)=>(
                  allProducts.products[department][0].hasOwnProperty('subdepartments') ? Object.keys(allProducts.products[department][0].subdepartments).map((sub, index)=>(
                    <Route path={`/${sub}`} key={sub}>
                      <ProductPage
                        department={sub}
                        currentProducts={currentProducts}
                        setCurrentSelection={setCurrentSelection}
                        setShowProduct={setShowProduct}
                        setMotherDepartment={setMotherDepartment}
                        motherDepartment={department}
                        departmentQuotes={departmentQuotes}
                        //updateCart={updateCart}
                        //cart={cart}
                      />
                    </Route>
                  ))
                  :''
                ))
                :
                <BlankProductPage />
            }
            {
              allProducts.hasOwnProperty('products') ? Object.keys(allProducts.products).map((department, index)=>(
                  <Route path={`/${department}`} key={department}>
                    <ProductPage
                      department={department}
                      currentProducts={currentProducts}
                      setCurrentSelection={setCurrentSelection}
                      setShowProduct={setShowProduct}
                      setMotherDepartment={setMotherDepartment}
                      motherDepartment={department}
                      departmentQuotes={departmentQuotes}
                      //updateCart={updateCart}
                      //cart={cart}
                    />
                  </Route>
              ))
              :
              <BlankProductPage />
            }
            <Route
              path="/showproductlink/:department/:id"
              children={<ShowProductLink
                          allProducts={allProducts.products}
                          showProduct={showProduct}
                          setShowProduct={setShowProduct}
                          updateCart={updateCart}
                          cart={cart}
                          cartUpdated={cartUpdated}
                          setCartUpdated={setCartUpdated}
                        />
              }
            />
            <Route path="/showproduct">
              <ShowProduct
                allProducts={allProducts.products}
                showProduct={showProduct}
                setShowProduct={setShowProduct}
                updateCart={updateCart}
                cart={cart}
                cartUpdated={cartUpdated}
                setCartUpdated={setCartUpdated}
              />
            </Route>
            <Route path="/collection">
              <Cart
                cart={cart}
                setCartItem={setCartItem}
                saveCartDatabase={saveCartDatabase}
                cartUpdated={cartUpdated}
                clearCart={clearCart}
                setCartUpdated={setCartUpdated}
                allProducts={allProducts}
              />
            </Route>
            <Route path="/search">
              <SearchPage
                searchValue={searchValue}
                searchResults={searchResults}
                lunrProducts={lunrProducts}
                setShowProduct={setShowProduct}
              />
            </Route>
            <Route path="/privacy_policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/terms_of_use">
              <TermsOfUse />
            </Route>
            <Route
              path="/personalized/collection/:id"
              children={<Child />}
             />
            <Route exact path="/">
              <Home
                cart={cart}
                departments={allProducts.hasOwnProperty('products') ? Object.keys(allProducts.products).sort() : []}
                allProducts ={allProducts}
                setCurrentSelection={setCurrentSelection}
                departmentTitle={shopByDepartment[randomNumber]}
              />
            </Route>
          </Switch>
        </header>
        <Footer
          departments={allProducts.hasOwnProperty('products') ? Object.keys(allProducts.products).sort() : []}
        />
      </div>
    </Router>
  );
};

export default App;

function Child(){
  let { id } = useParams();
  console.log(decodeURI(id))
  const [perColl, setPerColl] = useState({});
  const [roomNames, setRoomNames] = useState([]);
  const [roomExpanded, setRoomExpanded] = useState([]);
  const [amazonCart, setAmazonCart] = useState([]);

  useEffect(()=>{
    db.collection('personalized').doc(`${id}`).get().then((doc)=>{
      console.log('database called for personalized collection');
      if(doc.exists){
        console.log(doc.data());
        setPerColl(doc.data());
      }else{
        console.log(`Doc does not exist: ${id}`);
      }
    }).catch((error)=>{
      console.log(`Error loading personalized collection: ${error}`);
      console.log(error.message);
    });
  }, [id]);

  useEffect(()=>{
    let returnArray = [];
    let expansion = [];
    let tempAmazonCart = [];
    if(Object.hasOwnProperty.call(perColl, 'rooms')){
      let rooms = Object.keys(perColl.rooms);
      rooms.forEach((room)=>{
        returnArray[perColl.rooms[room].position] = room;
        expansion[perColl.rooms[room].position] = false;
        perColl.rooms[room].items.forEach((item)=>{
          if(item.source === 'Amazon'){
            tempAmazonCart.push({ASIN: item.ASIN, quantity: item.quantity});
          }
        });

      });
      console.log(tempAmazonCart);
      setAmazonCart(tempAmazonCart);
      console.log(returnArray);
      setRoomExpanded(expansion);
      setRoomNames(returnArray);
    }else{
      console.log('no rooms specified');
      setRoomNames(returnArray);
    }
  }, [perColl]);

  const expansionClicked =  (event, index)=>{
    let expansion = [...roomExpanded];
    expansion[index] = expansion[index] ? false : true;
    setRoomExpanded(expansion);
  };

  const itemClicked = (event, link)=>{
    console.log(event.target.className);
    //if(event.target.className !== 'decrement' && event.target.className !== 'increment' && event.target.className !== 'quantity'){
      console.log('item clicked: ' + link);
      //window.location.href = 'http://domain.com';
      window.open(link, '_blank');
    //}
  };
  
  return(
    <div className='personalizedBody'>
      <div className="personalizedTitle">
        <div>PERSONALIZED COLLECTION</div>
        <div className='personalizedSubtitle'>
          {id !== "" ? id : 'collection not found'}
        </div>
      </div>
      <div className='personalizedMain'>
        <div className="personalized_title_info_header">
          <div className='personalized_item_image'><img src={mwlogo} style={{width:'100%'}} alt="product pic"/></div>
          <div style={{paddingLeft: '20px', paddingTop: '0px'}}>
            <div className="personalized_item_title">Notes:</div>
            <div className="personalized_item_description">{perColl.note}</div>
            {
              perColl.amazon_cart_link ?
                <form method="GET" action="https://www.amazon.ca/gp/aws/cart/add.html" target="_blank">
                  <input type="hidden" name="AWSAccessKeyId" value="AKIAIF3N77DFOSS5KUZQ" /><br/>
                  <input type="hidden" name="AssociateTag" value="mwstylefinds-20" />
                  {
                    amazonCart.map((item, index)=>(
                      <span key={index}>
                        <input key={`ASIN_${index+1}`} type="hidden" name={`ASIN.${index+1}`} value={item.ASIN} />
                        <input key={`Quantity_${index+1}`} type="hidden" name={`Quantity.${index+1}`} value={item.quantity} />
                      </span>
                    ))
                  }
                  <div className="personalized_item_description_sub" style={{marginTop: '25px', marginBottom:'5px'}}>Press to select <u>all</u> Amazon items</div>
                  <input type="submit" className="button" name="add" value="CONTINUE TO AMAZON" />
                </form>
              :
                <div></div>
            }
          </div>
        </div>
        <div style={{width:'100%'}}>
        {
          Object.hasOwnProperty.call(perColl, 'rooms') ?
              roomNames.map((room, index)=>(
                <div key={index} className="roomContainer">
                    <div className="personalized_item_info_header">
                      <div className="personalized_item_info_header_title">
                        <div>{room}</div>
                        <div className="personalized_plus_minus" onClick={(event)=>expansionClicked(event, index)}>
                          {
                            roomExpanded[index] ?
                              <div>
                                <div>&#43;</div>
                              </div>
                            :
                              <div>
                                <div>&#8722;</div>
                              </div>
                          }
                        </div>
                      </div>
                      {
                        perColl.rooms[room].note === '' ?
                          <div></div>
                        :
                          !roomExpanded[index] ?
                            <div>
                              <div className="personalized_item_title">Room Notes:</div>
                              <div className="personalized_item_description">{perColl.rooms[room].note}</div>
                            </div>
                          :
                            <div></div>
                      }
                    </div>
                    {
                      !roomExpanded[index] ?
                        perColl.rooms[room].items.map((item, index)=>(
                          <div key={index} className="personalized_item_info" onClick={(event)=>itemClicked(event, item.site_stripe)}>
                            <div className='personalized_item_image'><img src={item.image_link} style={{width:'100%'}} alt="product pic"/></div>
                            <div style={{paddingLeft: '20px', paddingTop: '0px'}}>
                                <div style={{fontWeight:'lighter'}}>{item.name}</div>
                                <div className="personalized_item_title">Item Notes:</div>
                                {
                                  item.description ?
                                    <div className="personalized_item_description">{item.description}</div>
                                  :
                                    <div></div>
                                }
                                {
                                  item.price ?
                                    <div className="personalized_item_description"><span className="personalized_item_description_sub">CAD $</span>{item.price} each <span className="personalized_item_description_sub">as of {item.as_of_price_date}</span></div>
                                  :
                                    <div></div>
                                }
                                {
                                  item.quantity ?
                                    <div className="personalized_item_description">Recommended quantity: {item.quantity}</div>
                                  :
                                    <div></div>
                                }
                                <div className="personalized_item_title">View on {item.source}</div>
                            </div>
                          </div>
                        ))        
                      :
                        <div></div>
                    }
                </div>
              ))
          :
            <div></div>
        }
        </div>
      </div>
      <div className="personalized_item_description_sub">As an Amazon Associate I earn from qualifying purchases.</div>
    </div>
  )
}

/*
put back in under {PerColl.note}  currently line 544 near the logo section in personalizedMain css
{
              perColl.amazon_cart_link ?
                <form method="GET" action="https://www.amazon.ca/gp/aws/cart/add.html" target="_blank">
                  <input type="hidden" name="AssociateTag" value="mwstylefinds-20" />
                  {
                    perColl.cart.map((item,index) => (
                      <span key={index}>
                        <input key={`ASIN_${index+1}`} type="hidden" name={`ASIN.${index+1}`} value={item.ASIN} />
                        <input key={`Quantity_${index+1}`} type="hidden" name={`Quantity.${index+1}`} value={item.quantity} />
                      </span>
                    ))
                  }
                  <div className="personalized_item_description_sub" style={{marginTop: '25px', marginBottom:'5px'}}>Press to select <u>all</u> Amazon items</div>
                  <input type="submit" className="button" name="add" value="CONTINUE TO AMAZON" />
                </form>
              :
                <div></div>
            }
*/

/*
used to be in perColl.hasOwnproperty('cart') - now rooms
perColl.cart.map((item, index)=>(
                <div key={index} className="personalized_item_info" onClick={(event)=>itemClicked(event, item.site_stripe)}>
                  <div className='personalized_item_image'><img src={item.image_link} style={{width:'100%'}} alt="product pic"/></div>
                  <div style={{paddingLeft: '20px', paddingTop: '0px'}}>
                      <div style={{fontWeight:'lighter'}}>{item.name}</div>
                      <div className="personalized_item_title">Notes:</div>
                      {
                        item.description ?
                          <div className="personalized_item_description">{item.description}</div>
                        :
                          <div></div>
                      }
                      {
                        item.price ?
                          <div className="personalized_item_description"><span className="personalized_item_description_sub">CAD $</span>{item.price} each <span className="personalized_item_description_sub">as of {item.as_of_price_date}</span></div>
                        :
                          <div></div>
                      }
                      {
                        item.quantity ?
                          <div className="personalized_item_description">Recommended quantity: {item.quantity}</div>
                        :
                          <div></div>
                      }
                      <div className="personalized_item_title">View on {item.source}</div>
                  </div>
                </div>
              ))

*/

/*db.collection('products').get().then(querySnapshot=>{
          querySnapshot.docs.forEach((doc)=>{
            //firebaseData.products.push({[doc.id]: doc.data()});
            //firebaseData.products[`${doc.id}`] = [];
            let keys = Object.keys(doc.data());
            keys.forEach((key)=>{
              if(doc.data()[key].display && isDateAvailable(doc.data()[key].date_available_start, doc.data()[key].date_available_end)){
                //console.log(`${doc.data()[key].departments[0]}`);
                //console.log(firebaseData2.products.hasOwnProperty(`${doc.data()[key].departments[0]}`));
                doc.data()[key].departments.forEach((department, index)=>{
                  if(!firebaseData.products.hasOwnProperty(`${doc.data()[key].departments[index]}`)){
                    //console.log('first if true ' + doc.data()[key].departments[index]);
                    firebaseData.products[`${doc.data()[key].departments[index]}`] = [];
////////////////////////////////////////////////////////////////////////////firebaseDepartments.push({'department': doc.data()[key].departments[index], 'subdepartments': []})
                    if(doc.data()[key].hasOwnProperty('subdepartment')){
                      if(doc.data()[key].subdepartment !== "" && doc.data()[key].departments[index] !== 'new arrivals'){
                        //console.log('subdepartment----------------------- new');
                        firebaseData.products[`${doc.data()[key].departments[index]}`].push({'subdepartments' : {}});
                        firebaseDepartments[`${doc.data()[key].departments[index]}`] = [`${doc.data()[key].subdepartment}`];
                        if(!firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'].hasOwnProperty(`${doc.data()[key].subdepartment}`)){
                          firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'][`${doc.data()[key].subdepartment}`] = [];
                          console.log('push 1: ' + doc.data()[key].departments[index]);
                          productsForSearch.push(doc.data()[key]);
                          firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'][`${doc.data()[key].subdepartment}`].push(doc.data()[key]);
                        }else{
                          if(Array.isArray(firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'][`${doc.data()[key].subdepartment}`])){
                            console.log('push 2: ' + doc.data()[key].departments[index]);
                            productsForSearch.push(doc.data()[key]);
                            firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'][`${doc.data()[key].subdepartment}`].push(doc.data()[key]);
                          }
                        }
                      }else{
                        console.log('push 3: ' + doc.data()[key].departments[index]);
                        if(doc.data()[key].departments[index] !== 'new arrivals'){
                          productsForSearch.push(doc.data()[key]);
                        }
                        firebaseData.products[`${doc.data()[key].departments[index]}`].push(doc.data()[key]);
                      }
                    }else{
                      console.log('push 4: ' + doc.data()[key].departments[index]);
                      productsForSearch.push(doc.data()[key]);
                      firebaseData.products[`${doc.data()[key].departments[index]}`].push(doc.data()[key]);
                    }
                  }else{
                    //console.log('first if false ' + doc.data()[key].departments[index]);
                    if(doc.data()[key].hasOwnProperty('subdepartment')){
                      if(doc.data()[key].subdepartment !== "" && doc.data()[key].departments[index] !== 'new arrivals'){
                        //console.log('subdepartment----------------------- repeat');
                        firebaseDepartments[`${doc.data()[key].departments[index]}`].push(`${doc.data()[key].subdepartment}`);
                        if(!firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'].hasOwnProperty(`${doc.data()[key].subdepartment}`)){
                          firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'][`${doc.data()[key].subdepartment}`] = [];
                          console.log('push 5: ' + doc.data()[key].departments[index]);
                          productsForSearch.push(doc.data()[key]);
                          firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'][`${doc.data()[key].subdepartment}`].push(doc.data()[key]);
                        }else{
                          if(Array.isArray(firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'][`${doc.data()[key].subdepartment}`])){
                            console.log('push 6: ' + doc.data()[key].departments[index]);
                            productsForSearch.push(doc.data()[key]);
                            firebaseData.products[`${doc.data()[key].departments[index]}`][0]['subdepartments'][`${doc.data()[key].subdepartment}`].push(doc.data()[key]);
                          }
                        }
                      }else{
                        console.log('push 7: ' + doc.data()[key].departments[index]);
                        if(doc.data()[key].departments[index] !== 'new arrivals'){
                          productsForSearch.push(doc.data()[key]);
                        }
                        firebaseData.products[`${doc.data()[key].departments[index]}`].push(doc.data()[key]);
                      }
                    }else{
                      console.log('push 8: ' + doc.data()[key].departments[index]);
                      productsForSearch.push(doc.data()[key]);
                      firebaseData.products[`${doc.data()[key].departments[index]}`].push(doc.data()[key]);
                    }
                  }
                });

                //firebaseData.products[`${doc.id}`].push(doc.data()[key]);
              }
            });
          });
          setSubDepartments(firebaseDepartments);
          console.log(Object.keys(firebaseDepartments));

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          setLunrProducts(productsForSearch);
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        
          //return firebaseData;
        }).then((fbData)=>{
          // remove departments with no products to show
          console.log(fbData);
          let keys = Object.keys(fbData.products);
          console.log(keys);
          keys.forEach((key, index) => {
            if(fbData.products[key].length === 0){
              delete fbData.products[key];
            }
          });

          setAllProducts(fbData);
          console.log(fbData);

          //return fbData;
        }).catch((error)=>{
          console.log('Error getting products: ' + error);
        });*/